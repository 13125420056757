<template lang="pug">
  div
    .sidebar-layout__toolbar
      button.btn.btn--brand(
        v-permission-hide="permissions.create_housing"
        @click="modals.showCreateNew = true")
        font-awesome-icon.mr-1(
          size="sm"
          icon="plus")
        span {{ 'actions.add_housing' | translate }}
      v-dialog(
        max-width="350"
        v-model="modals.showCreateNew")
        create-new-modal(@close="modals.showCreateNew = false")
    navigation
    //- FOR DESKTOP AND TABLETS
    template(v-if="!isMobile")
      buildings-table
    //- FOR MOBILE ONLY
    template(v-if="isMobile")
      buildings-table-mobile
</template>

<script>
import Navigation from '../common/Navigation'
import adaptationMixin from '@/mixins/adaptation.mixin'
import CreateNewModal from './modals/CreateNewModal'
import permissions from '@/util/permissions'

const BuildingsTable = () => import('./BuildingsTable')
const BuildingsTableMobile = () => import('./BuildingsTableMobile')

export default {
  name: 'SettingsBuildings',

  mixins: [adaptationMixin],

  components: {
    Navigation,
    BuildingsTable,
    BuildingsTableMobile,
    CreateNewModal
  },

  data: () => ({
    modals: {
      showCreateNew: false
    },
    permissions: permissions
  })
}
</script>

<style lang="scss" scoped>
</style>
